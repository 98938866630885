import React, { useState } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import HeaderWithIcon from "components/Header/withIcon"

//DEPENDENCIES
import Column from "./Column"
import Filter from "componentsV3/Filter/menuFilter";
import MoreMenu from "components/MoreMenu";
import ExportAll from "./exportAll"
import PeriodFilter from "componentsV3/Filter/period"
import CardsLoader from "components/Card/loader"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"


//STYLE
import "./style.css"


//FUNCTIONS
import { getKanbanData, addColumn, reorder, automation } from "functions/kanban"
import { getUserAccess } from "functions/cards"
import { customApp, translate, getAppAccess } from "functions"
import { loadCardsV2 } from "functions/loadData"


function reorderList(list, startIndex, endIndex) {
    const result = Array.from(list);
    // return result.splice(startIndex, 1, list[endIndex]);
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

function AgileKanban(props) {
    const { loaderIds } = props
    const [search, setSearch] = useState(props.searchs && props.searchs[props.nodeId] ? props.searchs[props.nodeId] : null)
    const [loading, setLoading] = useState(false)
    const [preffers, setPreffers] = useState({})
    const [state, setState] = useState(getKanbanData(props.nodeId, { deleted: false, preffers }, props.cards, search))
    const { db } = reduxStore.getState()
    const [orderBy] = useState('order')
    const [deleted] = useState(false)
    const [exportBoard, setExportBoard] = useState(false)
    const [addColumnActive, setAddColumn] = useState(false)

    const AppAccess = getAppAccess()
    const CardAccess = getUserAccess(props.nodeId)
    const controls = React.useRef({
        lock: false,
        updateCards: false
    })

    const updateCards = (options) => {
        setState(getKanbanData(props.nodeId, options, props.cards, search))
    }

    React.useEffect(() => {
        if (props.searchs && props.searchs[props.nodeId] && props.searchs[props.nodeId] !== search) {
            setSearch(props.searchs[props.nodeId])
        }
    }, [props.searchs])

    React.useEffect(() => {
        if (!controls.current.lock) {
            updateCards({
                deleted: deleted,
                orderBy,
                preffers
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards, props.nodeId, search, preffers])


    async function onDragEnd(result) {

        controls.current = {
            ...controls.current,
            lock: false
        }
        if (!result.destination) {
            return
        }

        if (result.type === "column") {
            if (CardAccess < 5)
                return
            const columnOrder = reorderList(
                state.columnOrder,
                result.source.index,
                result.destination.index
            )
            let newColumnOrder = {}
            columnOrder.forEach((id, orderI) => {
                newColumnOrder = {
                    ...newColumnOrder,
                    [id]: {
                        ...reduxStore.getState().db.cards[id],
                        step: `${(orderI)}`
                    }
                }
            })
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    ...newColumnOrder
                }
            })

            let cardSource = reduxStore.getState().db.cards[columnOrder[result.source.index]]
            let cardTarget = reduxStore.getState().db.cards[columnOrder[result.destination.index]]
            if (
                cardSource
                && (
                    cardSource.showInPanel
                    || cardSource.onConcludedStepMoveTo
                )
            ) return

            if (
                cardTarget
                && (
                    cardTarget.showInPanel
                    || cardTarget.onConcludedStepMoveTo
                )
            ) return

            if (JSON.stringify(columnOrder) !== JSON.stringify(state.columnOrder))
                reorder(columnOrder, 'step')
            return
        }


        const sourceColumn = reduxStore.getState().db.cards[result.source.droppableId]

        //LINK
        let parentCard = reduxStore.getState().db.cards[result.destination.droppableId]
        let card = db.cards[result.draggableId]
        const { session } = reduxStore.getState()

        if (
            parseInt(parentCard.step) >= parseInt(sourceColumn.step)
            &&
            (card._requireApproval &&
                (
                    card._users
                    && card._users[session._id]
                    && !card._users[session._id].approvalResponsible
                )
            )
        ) return
        const items = Array.from(reorderList(
            state.columns[result.source.droppableId].items,
            result.source.index,
            result.destination.index
        ))

        let newCardsOrder = {}
        let cardsOrder = []
        items.forEach((card, orderI) => {
            newCardsOrder = {
                ...newCardsOrder,
                [card._id]: {
                    ...reduxStore.getState().db.cards[card._id],
                    order: `${(orderI)}`
                }
            }
            if (cardsOrder.indexOf(card._id) === -1)
                cardsOrder.push(card._id)
        })

        reorder(cardsOrder, 'order')

        if (
            parseInt(parentCard.step) > parseInt(sourceColumn.step)
            &&
            (card._requireApproval &&
                (
                    card._users
                    && card._users[session._id]
                    && !card._users[session._id].approvalResponsible
                )
            )
        ) return

        let dataUpdate = automation(props, card?._id || null, parentCard?._id || null, card?._createdBy?._id || null)
        if (parentCard.requireApproval) {
            dataUpdate = {
                ...dataUpdate,
                _requireApproval: true
            }
        } else if (!parentCard.requireApproval && card._requireApproval) {
            dataUpdate = {
                ...dataUpdate,
                _requireApproval: null
            }
        }

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [card._id]: {
                    ...reduxStore.getState().db.cards[card._id],
                    ...dataUpdate,
                    _parent: result.destination.droppableId,
                }
            }
        })
    }

    const newColumn = async (data) => {
        let response = await addColumn(data)
        if (response) {
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    ...response
                }
            })
        }
    }

    let filterFields = []
    if (props.nodeId && db.cards[props.nodeId] && db.cards[props.nodeId].type === "plan" && db.cards[props.nodeId].refs) {
        filterFields = [
            'text',
            'ref1',
            'ref2',
            'date',
            'tags',
            'status',
            'priority',
            'impact',
            'risk',
            'complexity',
            'users',
            'usersMode'
        ]
    } else {
        filterFields = [
            'text',
            'date',
            'tags',
            'status',
            'priority',
            'impact',
            'risk',
            'complexity',
            'users',
            'usersMode'
        ]
    }

    const selectPeriodCards = async (e) => {
        setLoading(true)
        const req = await loadCardsV2(props, {
            cardLoad: 'childrens',
            idRel: props.nodeId,
            completed: true,
            completedDays: e
        })
        if (req)
            setLoading(false)
    }

    return (
        <DragDropContext
            onBeforeDragStart={(e) => {
                controls.current = {
                    ...controls.current,
                    lock: true
                }
            }}
            onDragEnd={onDragEnd}
        >
            <div
                className="app"
                style={{
                    overflowY: "auto",
                    height: "-webkit-fill-available",
                    paddingTop: 50
                }}
            >
                <Droppable
                    droppableId="all-droppables"
                    direction="horizontal"
                    type="column"
                >
                    {provided => (
                        <div
                            {...provided.droppableProps}
                            className="columns"
                            ref={provided.innerRef}
                        >
                            {state.columnOrder.map((columnId, index) => (
                                <Column
                                    {...props}
                                    key={columnId}
                                    column={state.columns[columnId]}
                                    index={index}
                                    nodeId={props.nodeId}
                                    {...props.columProps ? props.columProps : {}}
                                    preffers={preffers}
                                />
                            ))}
                            {(
                                (
                                    AppAccess.admin
                                    && AppAccess.planAdmin
                                )
                                ||
                                CardAccess > 3
                            ) ?
                                <div className="column"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {!addColumnActive ?
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                backgroundColor: "#FFFFFF",
                                                color: "black",
                                                padding: 15,
                                                width: 279
                                            }}
                                        >
                                            <div id="AgileKanbanIndex_256">
                                                <CustomButton
                                                    title={translate("$__addColumn")}
                                                    text={translate("$__addColumn")}
                                                    color={"lightgrey"}
                                                    textColor={"lightgrey"}
                                                    icon={"add_box"}
                                                    onClick={() => {
                                                        setAddColumn(true)
                                                    }}

                                                    size={"25px"}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                backgroundColor: "#FFFFFF",
                                                width: 279,
                                                padding: 7
                                            }}
                                        >
                                            <div id="AgileKanbanIndex_282">
                                                <HeaderWithIcon
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    title={null}
                                                    color={customApp('menu')}
                                                    editable
                                                    permitIcon
                                                    onEdit={(e) => {
                                                        setAddColumn(false)
                                                        newColumn({
                                                            ...e,
                                                            _parent: props.nodeId,
                                                            type: "step",
                                                            step: String(state.columnOrder.length),
                                                            checklist: true
                                                        })
                                                    }}
                                                    placeholder={translate("$__columnName")}
                                                    onCancel={() => { setAddColumn(false) }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

            </div>
            <div
                style={{
                    position: "absolute",
                    top: 15,
                    left: 15,
                    height: 35,
                    display: "flex",
                    grid: 7,
                    alignItems: "center"
                }}
            >
                <MoreMenu
                    text={translate("$__options")}
                    icon={"settings"}
                    horizontalButton
                    size={'21px'}
                    transparent
                    style={{ position: "relative", border: '1px solid rgba(0, 0, 0, 0.47)', borderRadius: '5px', marginRight: "7px" }}
                    color={customApp('menu')}
                    btColor={"lightgray"}
                    options={[
                        {
                            name: translate("$__orderByPriority"),
                            icon: preffers.orderByPriority ? "toggle_on" : "toggle_off",
                            color: preffers.orderByPriority ? customApp("menu") : "#CCCCCC",
                            onClick: () => {
                                setPreffers({
                                    ...preffers,
                                    orderByPriority: preffers.orderByPriority ? false : true,
                                    ...!preffers.orderByPriority ? {
                                        orderByName: false,
                                        orderByCreatedDate: false,
                                        orderByUpdatedDate: false
                                    } : {}
                                })
                            },
                        },
                        {
                            name: translate("$__orderByName"),
                            icon: preffers.orderByName ? "toggle_on" : "toggle_off",
                            color: preffers.orderByName ? customApp("menu") : "#CCCCCC",
                            onClick: () => {
                                setPreffers({
                                    ...preffers,
                                    orderByName: preffers.orderByName ? false : true,
                                    ...!preffers.orderByName ? {
                                        orderByPriority: false,
                                        orderByCreatedDate: false,
                                        orderByUpdatedDate: false
                                    } : {}
                                })
                            },
                        },
                        {
                            name: translate("$__orderByCreatedDate"),
                            icon: preffers.orderByCreatedDate ? "toggle_on" : "toggle_off",
                            color: preffers.orderByCreatedDate ? customApp("menu") : "#CCCCCC",
                            onClick: () => {
                                setPreffers({
                                    ...preffers,
                                    orderByCreatedDate: preffers.orderByCreatedDate ? false : true,
                                    ...!preffers.orderByCreatedDate ? {
                                        orderByName: false,
                                        orderByPriority: false,
                                        orderByUpdatedDate: false
                                    } : {}
                                })
                            },
                        },
                        {
                            name: translate("$__orderByUpdatedDate"),
                            icon: preffers.orderByUpdatedDate ? "toggle_on" : "toggle_off",
                            color: preffers.orderByUpdatedDate ? customApp("menu") : "#CCCCCC",
                            onClick: () => {
                                setPreffers({
                                    ...preffers,
                                    orderByUpdatedDate: preffers.orderByUpdatedDate ? false : true,
                                    ...!preffers.orderByUpdatedDate ? {
                                        orderByName: false,
                                        orderByPriority: false,
                                        orderByCreatedDate: false
                                    } : {}
                                })
                            },
                        }
                        ,
                        {
                            name: translate("$__desc"),
                            icon: preffers.orderByDesc ? "toggle_on" : "toggle_off",
                            color: preffers.orderByDesc ? customApp("menu") : "#CCCCCC",
                            onClick: () => {
                                setPreffers({
                                    ...preffers,
                                    orderByDesc: preffers.orderByDesc ? false : true
                                })
                            },
                        },
                        {
                            name: translate("$__exportData"),
                            icon: "cloud_download",
                            color: customApp("menu"),
                            onClick: () => {
                                setExportBoard(true)
                            },
                        }
                    ]}
                />
                <Filter
                    nodeId={props.nodeId}
                    fields={filterFields}
                    showFilters
                />
                {exportBoard && !loading ?
                    <ExportAll onCompleted={() => { setExportBoard(false) }} data={state} fileName={db.cards[props.nodeId].name} />
                    : <></>}
                <PeriodFilter
                    style={{
                        color: customApp('menu'),
                        height: '20px',
                        width: '20px'
                    }}
                    title={translate(`$__completedRange`)}
                    showSelected
                    onSelect={
                        (e) => {
                            selectPeriodCards(e)
                        }
                    }
                />
                <div style={{ position: "relative", width: "100%", display: "flex", minWidth: 300 }}>
                    <CardsLoader loaderIds />
                </div>
            </div>
        </DragDropContext>
    )
}

const mapStateToProps = (store, props) => {
    return ({
        cards: store.db.cards,
        cardsExpanded: store.db.cardsExpanded,
        searchs: store.searchs,
    })
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AgileKanban)